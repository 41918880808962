<div om-layout
     om-layout-fill
     om-layout-align="start center"
     om-layout-gutter
     data-cy="smart-draft-in-chart-banner"
     class="padding-half">
  <ng-container *ngIf="loading; else defaultBanner">
    <button omgButton
            variant="link"
            class="-thin"
            disabled="true"
            data-cy="smart-draft-in-chart-button">
      <div om-layout
           om-layout-fill
           om-layout-gutter-mini
           om-layout-align="space-between center">
        <i class="fa fa-spinner fa-pulse"></i>
        <span>Running Smart Draft</span>
      </div>
    </button>
  </ng-container>
</div>

<ng-template #defaultBanner>
  <button omgButton
          variant="link"
          class="-thin"
          (click)="createSmartDraft.emit()"
          data-cy="smart-draft-in-chart-button">
    <div om-layout
         om-layout-fill
         om-layout-gutter-mini
         om-layout-align="space-between center">
      <span class="om-icon icon-magic"></span>
      <span>Run Smart Draft</span>
    </div>
  </button>
  <ng-container *ngIf="!draftComplete">
    <div om-layout
         om-layout-gutter
         om-layout-align="start center">
      <span>Turn a brief reply into a CICARE focused draft</span>
      <span [omgPopoverTriggerFor]="smartDraftPopover"
            triggerOn="hover">
        <span class="om-icon -small icon-alert-info"></span>
      </span>
    </div>
  </ng-container>
</ng-template>



<omg-popover [title]="'Smart Draft'"
             #smartDraftPopover>
  <div om-layout="vertical"
       om-layout-gutter-large
       data-cy="smart-draft-info-popover"
       class="padding-normal">
    <p>Smart Draft uses AI to help you draft empathetic, informed, and timely messages with patients.</p>
    <div om-layout="vertical">
      <b>How it works</b>
      <ul class="bulleted-list">
        <li>
          Write a brief reply to a patient such as "recommend visit"
        </li>
        <li>
          SmartDraft will turn your brief reply into a C-I-CARE focused message
        </li>
        <li>
          You will be able to review and modify the draft before sending
        </li>
      </ul>
    </div>
  </div>
</omg-popover>
