import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import {
  Document,
  DocumentSummary,
  Maybe,
} from '../../../../graphql/onelife.type';

export const GET_DOCUMENT_SUMMARY_QUERY = gql`
  query GetDocumentSummary($documentId: ID!) {
    document(id: $documentId) {
      summary {
        id
        createdAt
        updatedAt
        data
      }
    }
  }
`;

type GetDocumentSummaryResponse = {
  document: Document;
};

@Injectable({
  providedIn: 'root',
})
export class DocumentSummaryService {
  constructor(private apollo: Apollo) {}

  getDocumentSummary(documentId: number): Observable<Maybe<DocumentSummary>> {
    return this.apollo
      .use('onelife')
      .query<GetDocumentSummaryResponse>({
        query: GET_DOCUMENT_SUMMARY_QUERY,
        variables: {
          documentId,
        },
      })
      .pipe(map(response => response.data.document.summary || null));
  }
}
