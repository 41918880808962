<div om-layout
     om-layout-fill
     om-layout-align="start center"
     om-layout-gutter
     data-cy="smart-draft-feedback-banner"
     class="padding-half banner -instructional">
  <b>How did Smart Draft do?</b>
  <button omgButton
          variant="tiny"
          data-cy="upvote"
          [disabled]="isUpvote"
          (click)="saveFeedback(SmartDraftFeedback.Upvote)">
    <i *ngIf="isUpvote; else clickableUpvote"
       class="om-icon icon-thumbs-up-solid"></i>
  </button>
  <button omgButton
          variant="tiny"
          [disabled]="isDownvote"
          (click)="saveFeedback(SmartDraftFeedback.Downvote)">
    <i *ngIf="isDownvote; else clickableDownvote"
       class="om-icon icon-thumbs-down-solid"></i>
  </button>
</div>

<ng-template #clickableUpvote>
  <i class="om-icon icon-thumbs-up-regular"></i>
</ng-template>
<ng-template #clickableDownvote>
  <i class="om-icon icon-thumbs-down-regular"></i>
</ng-template>
