import { Component, Input, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

import { CollapsibleBannerComponent } from '@app/shared/components/collapsible-banner/collapsible-banner.component';

@Component({
  selector: 'omg-document-summary-feedback',
  templateUrl: './document-summary-feedback.component.html',
  styleUrls: ['./document-summary-feedback.component.scss'],
})
export class DocumentSummaryFeedbackComponent {
  feedbackForm: UntypedFormGroup;

  @ViewChild(CollapsibleBannerComponent) banner: CollapsibleBannerComponent;

  constructor(private formBuilder: UntypedFormBuilder) {
    const feedbackResponse = new UntypedFormControl();
    this.feedbackForm = this.formBuilder.group({
      feedbackResponse,
    });
  }

  onCancel() {
    this.banner.collapse();
  }

  onExpandChange(expanded: boolean) {
    if (!expanded) {
      this.resetFormValues();
    }
  }

  resetFormValues() {
    this.feedbackForm.reset({
      feedbackResponse: null,
    });
  }

  feedbackOptions = [
    {
      label: 'The summary is accurate and I found it useful',
      value: '0',
    },
    {
      label: 'The summary is accurate, but I did not find it useful',
      value: '1',
    },
    {
      label: 'The summary seems inaccurate',
      value: '2',
    },
  ];
}
