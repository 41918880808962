import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'omg-smart-draft-banner',
  templateUrl: './smart-draft-banner.html',
  styleUrls: ['./smart-draft-banner.component.scss'],
})
export class SmartDraftBannerComponent implements OnChanges {
  @Output() createSmartDraft = new EventEmitter();
  @Input() loading!: boolean;
  @Input() draftComplete!: boolean;

  // This makes sure the component updates visually to show / hide the loading spinner when the parent
  // component passes down a new "loading" value when the polling for the smart draft completes
  ngOnChanges(changes: SimpleChanges): void {
    if (
      !!changes.loading &&
      changes.loading.currentValue?.value !== changes.loading.previousValue?.value
    ) {
      this.loading = changes.loading.currentValue?.value;
    }
  }
}
