<div *ngIf="documentSummarizationVisible"
     class="document-summary">

  <omg-collapse [expanded]=true>
    <omg-collapse-toggle>
      <div om-layout
           om-layout-align="start center">
        <i class="fa-regular fa-wand-magic-sparkles"></i>
        <span class="document-summary-title">AI Document Summary</span>
        <omg-collapsed disableAnimation="true"><i class="fa fa-chevron-down"></i></omg-collapsed>
        <omg-expanded disableAnimation="true"><i class="fa fa-chevron-up"></i></omg-expanded>
      </div>
    </omg-collapse-toggle>

    <omg-expanded>
      <div *ngIf="loading$ | ngrxPush"><i class="fa fa-spinner fa-pulse"></i> Loading... This will only take a few
        moments.</div>
      <ng-container *ngIf="!(loading$ | ngrxPush) && summary$ | ngrxPush">
        <div *ngIf="bothSummaryVisible"
             class="document-summary-text">
          ----Detailed Summary Below----
        </div>
        <div *ngIf="detailedSummaryVisible"
             class="document-summary-text">
          <div *ngrxLet="detailedSummary$ as summary">
            <div *ngIf="summary.unavailable">
              <span class="om-icon icon-alert-critical inline-alert"></span>Summary Unavailable
            </div>
            {{summary.summary}}
          </div>
        </div>
        <div *ngIf="bothSummaryVisible"
             class="document-summary-text">
          ----Short Summary Below----
        </div>
        <div *ngIf="shortSummaryVisible"
             class="document-summary-text">
          <div *ngrxLet="shortSummary$ as summary">
            <div *ngIf="summary.unavailable">
              <span class="om-icon icon-alert-critical inline-alert"></span>Summary Unavailable
            </div>
            {{summary.summary}}
          </div>
        </div>
        <div om-layout
             om-layout-align="start center"
             om-layout-gutter
             class="document-summary-timestamp">
          <i class="fa-regular fa-wand-magic-sparkles"></i>
          <span>
            Generated {{(summary$ | ngrxPush)?.createdAt | omgDate: '4y'}} at
            {{(summary$ | ngrxPush)?.createdAt | omgDate: '12Time'}}
          </span>
        </div>
      </ng-container>
      <div *ngIf="!(loading$ | ngrxPush) && !(summary$ | ngrxPush)"
           class="document-summary-text">
        <span class="om-icon icon-alert-critical inline-alert"></span>Summary Unavailable
      </div>
    </omg-expanded>

    <omg-collapsed>
      <div class="document-summary-text-collapsed">
        <div *ngIf="loading$ | ngrxPush">
          <i class="fa fa-spinner fa-pulse"></i> Loading... This will only take a few moments.
        </div>
        <div *ngIf="!(loading$ | ngrxPush) && summary$ | ngrxPush"
             class="document-summary-text-collapsed">
          <span *ngIf="bothSummaryVisible">
            ----Detailed Summary Below----
          </span>
          <span *ngIf="detailedSummaryVisible">
            <div *ngrxLet="detailedSummary$ as summary">
              <div *ngIf="summary.unavailable">
                <span class="om-icon icon-alert-critical inline-alert"></span>Summary Unavailable
              </div>
              {{summary.summary}}
            </div>
          </span>
          <span *ngIf="bothSummaryVisible">
            ----Short Summary Below----
          </span>
          <span *ngIf="shortSummaryVisible">
            <div *ngrxLet="shortSummary$ as summary">
              <div *ngIf="summary.unavailable">
                <span class="om-icon icon-alert-critical inline-alert"></span>Summary Unavailable
              </div>
              {{summary.summary}}
            </div>
          </span>
        </div>
        <div *ngIf="!(loading$ | ngrxPush) && !(summary$ | ngrxPush)">
          <span class="om-icon icon-alert-critical inline-alert"></span>Summary Unavailable
        </div>
      </div>
    </omg-collapsed>
  </omg-collapse>
  <div *ngIf="documentSummaryFeedbackVisible">
    <omg-document-summary-feedback></omg-document-summary-feedback>
  </div>
</div>
